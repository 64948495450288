import React from "react";
import Layout from "../../components/Layout";
import {Link} from "gatsby";

// eslint-disable-next-line
export default () => (
  <Layout>
    <section className="section">
      <div className="container p-6">
        <div className="content" style={{minHeight: 40 + 'vh'}}>
          <h1>Dziękujęmy!</h1>
          <p>Skontaktujemy się najszybciej jak to możliwe.</p>
          <Link className="btn" to={'/'}>Powrót</Link>
        </div>
      </div>
    </section>
  </Layout>
);
